import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: () =>
            import ('../views/Login.vue'),
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
    },
    {
        path: '/dispensers',
        name: 'Dispensers',
        component: () =>
            import ('../views/Dispensers.vue'),
    },
    {
        path: '/dispenser/:id',
        name: 'Dispenser',
        component: () =>
            import ('../views/Dispenser.vue'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import ('../views/Profile.vue'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue'),
    },
    {
        path: '/terms-conditions',
        name: 'TermsCondition',
        component: () =>
            import ('../views/TermsCondition.vue'),
    }
]

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})