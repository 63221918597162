<template>
  <v-app>
    <NavBar :show="load"/>
    <v-main>
      <router-view/>
    </v-main>
    <Footer :show="load"/>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue';
import axios from "axios"
export default {
  name: 'App',

  components: {
    NavBar,
    Footer
  },

  data: () => ({
    load: true
  }),
  created(){
    this.validUser()
  },
  methods:{
    validUser(){
      let data = {
          'opcion': '7',
          'username': localStorage.getItem('username'),
          'jwt_token': localStorage.getItem('token')
      }
      if(localStorage.getItem('username') != null && localStorage.getItem('token') != null){
        axios.post(this.$pathAPI, data)
        .then((response) => {
            if(response.status == 200){
                let datos = response.data
                if(datos["mensaje"] == "Usuario no autorizado"){
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    localStorage.clear()
                    window.location.reload();
                    this.$router.push('/');
                }else{
                    this.load = false
                }
            }
        })
        .catch( (e) => {
            console.log(e)
            this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 2000});
        })
      }
    },
  }
};
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
  .v-main{
    font-family: 'Rubik', sans-serif;
    background: #D1ECF7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #D1ECF7, #D1ECF7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #D1ECF7, #D1ECF7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
</style>