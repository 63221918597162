<template>
    <div :hidden="show">
        <v-bottom-navigation 
            dark
            fixed
            color="white"
            shift
            grow
            app
            v-model="value"
        >
            <v-btn to="/home" value="home">
                <span>Inicio</span>
                <v-icon size="32px">mdi-home</v-icon>
            </v-btn>
            <v-btn to="/dispensers" value="dispensers">
                <span>Dispensadores</span>
                <v-icon size="32px">mdi-paw</v-icon>
            </v-btn>
            <v-btn to="/profile" value="profile">
                <span>Perfil</span>
                <v-icon size="32px">mdi-account-box</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
export default {
    props: {show: {
      type: Boolean,
      default: true
    }},
    name: 'Footer',
    data(){
        return{
            value: null
        }
    }
}
</script>
<style scoped>
    .link{
        text-decoration: none;
        color: white;
    }
    .v-bottom-navigation{
        background: #1488CC;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to top, #004e92, #004e92);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #004e92, #004e92); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
</style>